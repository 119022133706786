<!--
 * @Author: wang peng
 * @Description: 互联电站列表
-->
<template>
  <scroll-layout class="interconnectionStation -list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              v-rbac="'asset:station:connection:sync'"
              type="plain"
              @click="syncSiteClick">
              同步站点
            </ykc-button>
            <ykc-button
              v-rbac="'asset:station:connection:export'"
              type="plain"
              @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
      <!--自定义列弹窗--start -->
      <ykc-operation-dialog
        show-footer
        title="同步站点"
        :show.sync="showDialog"
        :before-close="onCLose"
        :before-cancel="onCLose"
        :before-confirm="dialogBeforeConfirm">
        <div slot="content">
          <el-form
            size="mini"
            ref="customRuleForm"
            class="customRuleForm img-error"
            bottomTip="温馨提示：同步站点请发送后需等待，请刷新页面查看"
            :rules="customRules"
            :model="customRuleForm">
            <el-form-item label="互联商户名称" prop="operatorId">
              <ykc-dropdown
                :remote="true"
                :data="OperatorListData"
                v-model="customRuleForm.operatorId"></ykc-dropdown>
            </el-form-item>
          </el-form>
        </div>
      </ykc-operation-dialog>
      <!--自定义列弹窗--end -->
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { offlineExport, code } from '@/utils';
  import { stationPage, directPage } from '@/service/apis';

  export default {
    name: 'interconnectionStationList',
    components: {},
    data() {
      return {
        showDialog: false,
        drawerType: 'edit',
        searchParams: {
          type: 2,
          name: '',
          detailAddress: '',
          startStatus: '',
          stationId: '',
          stationOperatorId: '',
        },
        OperatorListData: [], // 归属商户
        tableTitle: '互联电站列表', // 列表表格
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '电站编码', prop: 'stationId', minWidth: '150px' },
          { label: '电站名称', prop: 'name', minWidth: '150px' },
          { label: '电站地址', prop: 'detailAddress', minWidth: '200px' },
          { label: '入驻时间', prop: 'createTime', minWidth: '150px' },
          { label: '归属商户', prop: 'operatorName', minWidth: '150px' },
          {
            label: '终端数量',
            prop: 'directTerminalNum',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <div class="flex">
                    <div style="margin-right:4px">
                      <span class="row-item row-green">直</span>
                      <span class="row-item-txt">{row.directTerminalNum ?? '--'}</span>
                    </div>
                    <div>
                      <span class="row-item row-blue">交</span>
                      <span class="row-item-txt">{row.alternateTerminalNum ?? '--'}</span>
                    </div>
                  </div>
                );
              },
            },
          },
          {
            label: '电站状态',
            prop: 'startStatus',
            scopedSlots: {
              default: ({ row }) => {
                const startStatus = row.startStatus === 1 ? 'status-blue' : 'status-red';
                return (
                  <span class={['userStatus', startStatus]}>
                    {row.startStatus === 1 ? '启用' : '停用'}
                  </span>
                );
              },
            },
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        customRuleForm: {
          operatorId: '',
        },
        customRules: {
          operatorId: [{ required: true, message: '请至少选择一个列选项', trigger: 'change' }],
        },
      };
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'detailAddress',
            label: '电站地址',
            placeholder: '请输入电站地址',
          },
          {
            comName: 'YkcInput',
            key: 'stationId',
            label: '电站编码',
            placeholder: '请输入电站编码',
          },
          {
            comName: 'YkcDropdown',
            key: 'startStatus',
            label: '启停状态',
            placeholder: '请选择启停状态',
            data: [
              all,
              ...[
                { id: '1', name: '启用' },
                { id: '0', name: '停用' },
              ],
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'stationOperatorId',
            label: '归属商户',
            placeholder: '请选择归属商户',
            data: this.OperatorListData,
          },
        ];
      },
    },
    created() {
      const { id } = this.$route.query;
      this.searchParams.stationOperatorId = id;
      this.requestOperatorList();
      this.requestList();
      this.initData();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            id: '2',
            text: '详情',
            enabled: () => code('asset:station:connection:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/plantAssets/powerStationManagement/interconnectionStation/Detail',
                query: { stationId: row.stationId },
              });
            },
          },
          {
            id: '3',
            text: '同步价格',
            enabled: () => code('asset:station:connection:syncprice'),
            handleClick: (btn, { row }) => {
              this.synchronizePrice(row);
            },
          },
          {
            id: '4',
            text: '电站活动',
            enabled: () => code('URL:marketing:platform:price'),
            handleClick: (btn, { row }) => {
              const routeUrl = this.$router.resolve({
                path: '/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity',
                query: {
                  stationName: row.name,
                },
              });
              window.open(routeUrl.href, '_blank');
            },
          },
          {
            enabled: () => code('asset:station:connection:enable'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.startStatus === 1}
                onClick={() => {
                  this.doAbleActivity(row);
                }}>
                启用
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:connection:enable'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.startStatus === 0}
                onClick={() => {
                  this.doDisableActivity(row);
                }}>
                停用
              </ykc-button>
            ),
          },
        ];
      },
      /**
       * 同步站点
       * */
      syncSiteClick() {
        this.showDialog = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'station_export',
            jsonNode: {
              type: 2,
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * @desc 同步价格
       * */
      synchronizePrice(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认对该电站同步价格吗？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            directPage
              .syncBusinessPolicy({
                stationId: row.stationId,
                operatorId: row.operatorId,
                connectorID: row.connectorID,
              })
              .then(res => {
                console.log(res);
                done();
                this.$message({
                  message: '同步成功',
                  type: 'success',
                });
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
          },
        });
      },
      /**
       * @desc 单个启用
       * */
      doAbleActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认启用该电站吗？',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            stationPage
              .intStatusSwitch({ stationIdList: [row.stationId], startStatus: 1 })
              .then(res => {
                console.log(res);
                this.$message({
                  message: '启用成功',
                  type: 'success',
                });
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * @desc 单个停用
       * */
      doDisableActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '停用后，电站将不对外显示，同时无法启动充电！不影响正在充电的订单',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            stationPage
              .intStatusSwitch({ stationIdList: [row.stationId], startStatus: 0 })
              .then(res => {
                console.log(res);
                this.$message({
                  message: '停用成功',
                  type: 'success',
                });
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        Object.assign(this.searchParams, searchData);
        this.pageInfo.current = 1;
        this.searchParams.type = 2;
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        this.searchParams.type = 2;
        console.log('清空查询', form);
        this.requestList();
      },
      /**
       * 弹窗关闭
       * */
      onCLose(done) {
        this.showDialog = false;
        done();
      },
      /**
       *  弹窗确认
       * */
      dialogBeforeConfirm(done) {
        console.log('确认', this.customRuleForm);
        this.$refs.customRuleForm.validate(valid => {
          if (valid) {
            directPage
              .syncStationInfos({ operatorId: this.customRuleForm.operatorId })
              .then(res => {
                console.log('res+++', res);
                this.showDialog = false;
                done();
                this.$message({
                  message: '同步成功',
                  type: 'success',
                });
              })
              .catch(err => {
                console.log(err);
              });
          }
        });
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 获取归属商户列表
       */
      requestOperatorList() {
        this.OperatorListData = [];
        directPage
          .userOperatorList({ operatorType: '2' })
          .then(res => {
            const params = res;
            params.map(item => {
              return this.OperatorListData.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        stationPage
          .intFindByPage(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .interconnectionStation -list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }
        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }
        .cell .status-blue::after {
          background: #02c498;
        }
      }
      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }
  .ykc-table {
    .el-table .el-table__row {
      .cell .userStatus {
        padding-left: 8px;
        position: relative;
      }
      .cell .userStatus::after {
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #d0021b;
        position: absolute;
        top: 5.5px;
        left: 0;
      }
      .cell .status-blue::after {
        background: #4d8cfd;
      }
      .cell .status-green::after {
        background: #02c498;
      }
      .cell .status-black::after {
        background: #9b9b9b;
      }
    }
    .sealBtn {
      color: #4d8cfd;
      cursor: pointer;
    }
    .row-item {
      padding: 0px 4px;
      font-size: 10px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 7px;
      display: inline-block;
    }

    .row-item-txt {
      padding: 0 4px;
      min-width: 30px;
      text-align: center;
      display: inline-block;
    }
    .row-green {
      background: linear-gradient(180deg, #07c160 0%, #02e495 100%);
    }
    .row-blue {
      background: linear-gradient(180deg, #1779fe 0%, #72aeff 100%);
    }
  }
</style>
